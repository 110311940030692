














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ProjectCard extends Vue {
  @Prop() readonly project!: Project

  @Prop() readonly onClick!: Function
}
