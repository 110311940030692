
























import { Component, Vue, Prop } from 'vue-property-decorator';
import hljs from '../highlight';

@Component
export default class Article extends Vue {
  @Prop() readonly path!: string;

  date = '';

  html = '';

  async created() {
    if (!this.$store.getters.articles.length) await this.$store.dispatch('fetchArticles');

    const { date, html } = this.$store.getters.html(this.path);
    this.date = date;
    this.html = html;
  }

  mounted() {
    Article.handleSyntaxHighlight();
  }

  updated() {
    Article.handleSyntaxHighlight();
  }

  static handleSyntaxHighlight() {
    const blocks: NodeListOf<HTMLElement> = document.querySelectorAll('pre code');

    blocks.forEach((block) => { hljs.highlightBlock(block); });
  }

  handleGoBack() {
    if (window.history.length > 2) window.history.back();
    else this.$router.push({ path: '/' });
  }
}
