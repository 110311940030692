
















import { Component, Mixins } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import Cards from './Cards.vue';

@Component({
  components: { Cards },
  computed: mapGetters([
    'articles',
  ]),
  methods: mapActions([
    'fetchArticles',
  ]),
})
export default class Articles extends Mixins(Cards) {
  articles!: Article[]

  fetchArticles!: () => void

  type = 'ARTICLE';

  async created() {
    if (!this.articles.length) this.fetchArticles();
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  changeRoute(path: string) {
    this.$router.push({ path: `/articles/${path}` });
  }
}
