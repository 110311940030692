

































import { Component, Vue, Prop } from 'vue-property-decorator';
import ArticleCard from './ArticleCard.vue';
import ProjectCard from './ProjectCard.vue';

@Component({
  components: { ArticleCard, ProjectCard },
})
export default class Cards<T> extends Vue {
  numColumn = -1;

  @Prop() readonly cards!: T[]

  @Prop() readonly onClick!: Function

  @Prop() readonly cardType!: string

  cardRows = Array<Array<T>>(Array<T>());

  async created() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const width = window.innerWidth;
    let tmp: number;

    if (width < 680) {
      tmp = 1;
    } else if (width >= 680 && width < 992) {
      tmp = 2;
    } else {
      tmp = 3;
    }

    if (tmp !== this.numColumn && this.cards) {
      this.numColumn = tmp;
      this.generateRows();
    }
  }

  generateRows() {
    const emptyCards = [];
    const mod = this.cards.length % this.numColumn;
    if (mod) {
      for (let i = 0; i < this.numColumn - mod; i += 1) {
        emptyCards.push({} as T);
      }
    }

    this.cardRows = this.cards.concat(emptyCards).reduce((rows: T[][], card: T, index: number) => {
      if (index % this.numColumn === 0) {
        rows.push([card]);
      } else {
        rows[rows.length - 1].push(card);
      }
      return rows;
    }, []);
  }
}
