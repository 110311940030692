















import { Component, Vue } from 'vue-property-decorator';
import Cards from './Cards.vue';
import projects from '../data';

@Component({
  components: { Cards },
})
export default class Projects extends Vue {
  projects = projects;

  type = 'PROJECT';

  openTab(url: string) {
    window.open(url);
  }
}
