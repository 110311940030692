














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ArticleCard extends Vue {
  @Prop() readonly article!: Article

  @Prop() readonly onClick!: Function
}
